import React from 'react';
import PropTypes from 'prop-types'
import Collapsible from 'react-collapsible'
import * as style from './capabilities.module.scss'

const CapabilitiesMobile = ({ content }) => {
  return (
    <div className={style.capabilitiesMobile}>
      {content.map((capability) => {
        return (
          <Collapsible trigger={capability.title} easing="ease" key={capability.title}>
            <div dangerouslySetInnerHTML={{ __html: capability.copy + '<br/>' }} />
          </Collapsible>
        )
      })}
    </div>
  )
}

CapabilitiesMobile.propTypes = {
  content: PropTypes.array.isRequired
}

export default CapabilitiesMobile