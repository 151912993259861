import React from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'

import ConditionalWrapper from '../ConditionalWrapper'
import Slider from "react-slick";
import { useViewport } from '../../utils/useViewport'
import * as style from './recentnews.module.scss'

const RecentNewsItem = ({ title, MentionData }) => {
  return (
    <a href={MentionData.url} className={style.recentNewsItem} target="_blank" rel="noreferrer">
      <strong dangerouslySetInnerHTML={{ __html: title }} />
      Zobacz artykuł &rarr;
    </a>
  )
}

const RecentNews = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpMention(limit: 6, sort: {fields: MentionData___date, order: DESC}) {
        nodes {
          title
          MentionData {
            url
          }
        }
      }
    }
  `)
  const settings = {
    adaptiveHeight: true,
    // arrows: false,
    centerMode: true,
    centerPadding: '10%',
    // dots: true,
    infinite: false
  }
  const isMobile = useViewport()

  return (
    <section className={style.recentNews}>
      <div className={style.recentNewsHeader}>
        <h2>Aktualności</h2>
        <Link to="/news/" className="mh-button">Zobacz wszystkie aktualności</Link>
      </div>
      <div className={style.recentNewsContent}>
        <ConditionalWrapper condition={isMobile} wrapper={children => <Slider {...settings}>{children}</Slider>}>
          {data.allWpMention.nodes.map(news => <RecentNewsItem {...news} key={news.title} />)}
        </ConditionalWrapper>
      </div>
    </section>
  )
}

export default RecentNews