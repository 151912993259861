import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import CapabilitiesDesktop from "./capabilitiesDesktop"
import CapabilitiesMobile from "./capabilitiesMobile"
import * as style from './capabilities.module.scss'

const Capabilities = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(slug: {eq: "about"}) {
        RepeatableContent {
          content {
            title
            copy
          }
        }
      }
    }
  `)

  return (
    <section className={style.capabilities}>
      <CapabilitiesMobile {...data.wpPage.RepeatableContent} />
      <CapabilitiesDesktop {...data.wpPage.RepeatableContent} />
    </section>
  )
}

export default Capabilities
