import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Capabilities from "../components/about/capabilities"
import Description from "../components/description"
import Diversity from "../components/about/diversity"
import Globe from "../components/globe/globe"
import OurLeadership from "../components/about/ourLeadership"
import PageData from "../components/pageData"
import RecentNews from "../components/about/recentNews"
import Timeline from "../components/about/timeline"

import "../styles/page-about.scss"
import Mini1 from '../images/blobs/mini1.inline.svg'
import Mini2 from '../images/blobs/mini2.inline.svg'

const PageAbout = ({ data }) => {
  const page = data.wpPage

  return (
    <Layout id="about">
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
      />
      <div className="page-header">
        <PageData {...page.PageData} />
        <div className="page-header-copy">
          <h1>{page.title}</h1>
          <Description {...page.Description} />
        </div>
      </div>

        {/*<div className="about-hero">
        <div className="about-hero-wrap">
          <GatsbyImage
            image={page.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
            alt={page.featuredImage.node.altText}
            title={page.featuredImage.node.title} />
        </div>
      </div>*/}

      <div className="blob-wrap">
        <Mini1 style={{transform: 'translateY(-50%)', right: '-33%'}} />
      </div>

      <div className="page-dark">
        <Capabilities />

        <OurLeadership />

        <div className="blob-wrap small">
          <Mini2 style={{transform: 'translateY(-20%)', right: '-12%'}} />
        </div>
        <Diversity />
      </div>

      <RecentNews />

    </Layout>
  );
}

export default PageAbout

export const query = graphql`query ($slug: String) {
  wpPage(slug: {eq: $slug}) {
    title
    featuredImage {
      node {
        altText
        title
        localFile {
          childImageSharp {
            gatsbyImageData(height: 320, layout: FULL_WIDTH)
          }
        }
      }
    }
    Description {
      description
    }
    PageData {
      megaNumber
      miniText
    }
    SeoMeta {
      seoTitle
      seoDescription
    }
  }
}
`
  