import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import OurLeadershipWrap from "./ourLeadershipWrap"

const OurLeadership = () => {
  const data = useStaticQuery(graphql`{
  allWpPerson(
    sort: {fields: contentType___node___menuPosition, order: ASC}
    filter: {PersonData: {market: {name: {ne: null}}}}
  ) {
    nodes {
      title
      PersonData {
        bio
        jobTitle
        market {
          name
          slug
          databaseId
        }
      }
      featuredImage {
        node {
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`)

  return (
    <OurLeadershipWrap people={data.allWpPerson.nodes} />
  )

}

export default OurLeadership
