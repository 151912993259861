import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from "gatsby-plugin-image";
import * as style from './timeline.module.scss'

class TimelineDesktop extends React.Component {
  constructor({ timeline, desktop }) {
    super()
    this.state = {
      active: 0
    }
  }

  handleClick(index) {
    this.setState({active: index})
  }

  render() {
    const timeline = this.props.timeline
    const desktop = this.props.desktop
    return (
      <div className={style.timelineDesktop}>
        <ul className={style.columnMenu}>
          {timeline.map((item, i) => {
            const className = this.state.active === i ? style.menuItemActive : style.menuItem
            return (
              <li key={i}>
                <button className={className} onClick={() => this.handleClick(i)}>
                  {item.date}
                </button>
              </li>
            )
          })}
        </ul>

        <div className={style.fakeH2}>Our Story</div>
        <GatsbyImage image={desktop} alt="Our Agency Timeline" className={style.desktopImage} />

        <div className={style.columnContent}>
          <div dangerouslySetInnerHTML={{ __html: timeline[this.state.active].copy }} />
        </div>
      </div>
    );
  }
}

TimelineDesktop.propTypes = {
    timeline: PropTypes.array.isRequired
}

export default TimelineDesktop
