import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import * as style from './diversity.module.scss'

const Diversity = () => {
  const data = useStaticQuery(graphql`{
  wpPage(slug: {eq: "about"}) {
    AboutData {
      diversity {
        title
        description
        column1
        column2
        image {
          altText
          title
          localFile {
            childImageSharp {
              gatsbyImageData(width: 960, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`)

  const diversity = data.wpPage.AboutData.diversity
  return (
    <section id={style.diversity}>
      <h2>{diversity.title}</h2>
      <p className={style.description}>{diversity.description}</p>
      <GatsbyImage
        image={diversity.image.localFile.childImageSharp.gatsbyImageData}
        alt={diversity.image.altText}
        title={diversity.image.title} />
      <div className={style.columnWrap}>
        <p>{diversity.column1}</p>
        <p>{diversity.column2}</p>
      </div>
    </section>
  );
}

export default Diversity
