import React from 'react'
import PropTypes from 'prop-types'
import * as style from './capabilities.module.scss'

class CapabilitiesDesktop extends React.Component {
  constructor({ content }) {
    super()
    this.state = {
      active: 0
    }
  }

  handleClick(index) {
    this.setState({active: index})
  }

  render() {
    const content = this.props.content
    return (
      <div className={style.capabilitiesDesktop}>
        <ul className={style.columnMenu}>
          {content.map((capability, i) => {
            const className = this.state.active === i ? style.menuItemActive : style.menuItem
            return (
              <li key={i}>
                <button className={className} onClick={() => this.handleClick(i)}>
                  {capability.title}
                </button>
              </li>
            )
          })}
        </ul>
        <div className={style.columnContent}>
          <h3>{content[this.state.active].title}</h3>
          <div dangerouslySetInnerHTML={{ __html: content[this.state.active].copy }} />
        </div>
      </div>
    )
  }
}

CapabilitiesDesktop.propTypes = {
    content: PropTypes.array.isRequired
}

export default CapabilitiesDesktop
