import React from "react"
import PropTypes from 'prop-types'
import Leadership from '../leadership'
import * as style from './ourleadership.module.scss'

class OurLeadershipWrap extends React.Component {
  constructor({ people }) {
    super()

    this.peopleByMarket = people.reduce((pbm, person) => {




      const market = person.PersonData.market.name
      const exists = pbm.find(item => item.market === market)
      exists ? exists.people.push(person) : pbm.push({market: market, people: [person]})
      return pbm
    }, [])

    this.state = {
      active: 0
    }
  }

  handleMarket(i) {
    this.setState({
      active: i
    })
  }



  render() {



    return (
      <section id={style.ourLeadership}>
        <h2>Liderzy</h2>
        <div className={style.buttons}>
          {this.peopleByMarket.map(({market}, i) => 
            <button key={i} onClick={() => this.handleMarket(i)} className={i === this.state.active ? style.active : ''}>{market}</button>
          )}
        </div>
        <Leadership people={this.peopleByMarket[this.state.active].people} />
      </section>
    )
  }
}

OurLeadershipWrap.propTypes = {
  people: PropTypes.array.isRequired
}

export default OurLeadershipWrap
