import React from "react"
import debounce from "lodash.debounce"

// Returns Boolean if we're on a small screen or not
const isMobileViewport = () => {
  if (typeof window !== `undefined`) {
    return window.matchMedia(`(max-width: 767px)`).matches
  }
  return false
}

export const useViewport = () => {
  const [isMobile, setMobile] = React.useState(isMobileViewport())

  React.useEffect(() => {
    const handleWindowResize = debounce(() => {
      setMobile(isMobileViewport())
    }, 350)

    window.addEventListener(`resize`, handleWindowResize)
    return () => window.removeEventListener(`resize`, handleWindowResize)
  }, [])

  return isMobile
}
